/* Split the screen in half */
/* .split {
    height: 100%;
    width: 75%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    
  } */
  
  /* Control the left side */
  .left {
    left: 0;
    width: 25%;
    background-color: blue;
    /* resize: horizontal; */
    /* padding-top: 35px; */
  }
  
  /* Control the right side */
  /* .right {
    right: 0;
    float: right;
    width: 75%;

  } */

  .render {
    /* padding-left: 30px;
    padding-top: 50px; */
    padding: 3% 3% 0% 3%;
    /* margin: 2rem; */
  }

  /* .navbar>.container {
    display: flex;
  } */

  /* .navbarContainer {
    margin: 2% 2% 2% 2%;
  } */

  .dropdown {
    float: right;
  }

  #profileDropDown.btn-primary {
    color: grey;
    background-color: transparent;
    border: transparent;
    /* float: right; */
    /* margin-right: 10px; */
  } 
  
  /* If you want the content centered horizontally and vertically */
  .centered {
    position: absolute;
    top: 40%;
    left: 50%;
    /* right: 50%; */
    transform: translate(-50%, -50%);
    /* transform: scale(1); */
    text-align: center;
  }
  
  /* Style the image inside the centered container, if needed */
  .centered img {
    width: 150px;
    border-radius: 50%;
  }


.change-height {
  height: 100vh;
  background-color: skyblue;
}

/* .btn-lg {
  width: 250px;
} */

#atlwdg-trigger {
  left: 80%;
  bottom: 20px;
  top: 92%;
}

body.h-100 {
  overflow: auto;
}
/* .atlwdg-trigger.atlwdg-TOP {
  left: 45%;
  top: 90%
} */

.nav-container {
  width: 100%;
  /* display: flex; */
}

.content {
  display: flex;
  align-items: center;
  justify-content: center
}
.text, .content {
  padding-left: 10px;

}

.text b,
.text p{
    /* margin-left: 5px; */
    display: block;
    margin: 2px 0 0 0;
    /* padding-left: 10px; */
    width: fit-content;
}

.navItem {
  align-items: center;
  text-align: center;
  justify-content: center;
}


