.table thead th {
    vertical-align: middle;
    text-align: center;
    align-items: center;
}

#link {
    vertical-align: middle;
    text-align: center;
    align-items: center;
}

.svg-inline--fa.fa-w-12 {
    color: blue;
    cursor: pointer;
}

.svg-inline--fa.fa-w-12.disabled {
    color: grey;
    cursor: not-allowed;
    /* pointer-events: none; */
}

.table td {
    padding: '.75rem';
    border-top: 1px solid #dee2e6;
    vertical-align: middle;
}

thead.tableHead {
    background-color: azure;
    /* table-layout: fixed; */
    /* width: 500px; */
}

/* .tableHead tr th {
    overflow: hidden;
} */

th.thHead {
    /* table-layout: fixed; */
    width: 300px;    
}

td.tdRow {
    /* table-layout: fixed; */
    /* width: 300px; */
    width: 150px;
    overflow: auto;
}

p {
    /* width: 275px; */
    width: 150px;
    word-wrap: break-word;
    /* margin-top: 10px; */
}

.fa-download {
    font-size: 2.5rem;
}

.form-check-input {
    position: relative;
    margin-top: .3rem;
    margin-left: .4rem;
}

#loader {
    position: absolute;
    top: 50%;
    left: 50%;
    vertical-align: middle;
    align-items: center;
    margin-top: 50px;
}

#paginationBttns {
    /* position: fixed; */
    bottom: 0px;
    left: 30%;
    display: flex;
    justify-content: center;
    bottom: 0px;
}

#icon {
    float: right;
    vertical-align: middle;
    margin-right: 20px;
}


#required-field {
    color: #008000;
}

#required-field1 {
    color: #fefe33;
}

#required-field2 {
    color: red;
}

.pagination-wrapper {
    overflow: auto;
    display: block;
    /* align-items: center; */
    /* margin-left: 10%; */
}
  
/* .pagination {
    justify-content: center;  
    align-items: center;
    display: flex;
} */
  
  /* .data-slice {
    margin-top: 15px;
  } */

  #customers {
    /* font-family: Arial, Helvetica, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    overflow: auto
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: middle;
    /* width: 800px; */
  }
  /* #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: middle;
  } */
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    background-color: #4CAF50;
    color: white;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    
  }

th#thHead, td#tdRow {
    table-layout: fixed;
    /* width: 200px; */
    /* overflow: auto; */
    /* width: 400px; */
    width: 150px;
    border: 1px solid #ddd;  

}

#example, #example_wrapper { overflow-x: scroll; margin-bottom: 10px; }