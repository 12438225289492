.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.mt-5 {
  margin-top: 0rem!important;

}

.m-auto {
  margin: 0% !important;
}

.navbar {
  padding: 1rem;
}

/* .navbar>.container {
  display: flex;
} */

.card {
  box-shadow: 0px 0px 2px 2px silver;
  /* -webkit-box-shadow: 1px 1px 1px grey;
  -moz-box-shadow: 1px 1px 1px grey; */
}

.container {
  margin-left: 100px;
  margin-right: 100px;
  /* margin: 0px; */
  width: 100%;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}



@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.navbar-brand {
  margin-right: 0px;
  font-size: 1rem;
}

.text-wrap {
  word-break: break-all;
}
