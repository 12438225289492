#profileDropDown {
    /* width: 30%; */
    float: right;
}

/* #dropdownItem {
    text-align: center;
    width: 300px;
    height: 250px;
} */

#button {
    float: right;
    margin-right: 10px;
}

#dropdownMenu {
    /* padding: inherit; */
    text-align: center;
    width: 250px;
    /* height: 250px; */
    padding-top: 30px;
}