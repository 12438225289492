.custom-file {
    width: 50%;
}

span.css-1okebmr-indicatorSeparator {
    background-color: transparent;
}

/* #nextRow {
    width: 50%;
} */

#arrowExchange {
    margin-top: 40px;
    vertical-align: middle;
}

.disclaimer{
    font-size: 14px;
    line-height: 1.2;
}